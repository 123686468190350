import React, {useEffect} from "react";
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, InputGroup, ButtonGroup, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getProducts} from '../Products/ProductFunctions.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import {getOwners} from "../Owners/OwnerFunctions";

moment.tz.setDefault("America/Toronto");

class AccountingList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);

        this.state = {
            transactions: [],
            loading: false,
            // default search settings
            category: null,
            action: null,
            product_id: null,
            startDate: moment().subtract(29, 'days'),
            endDate: moment().endOf('day'),
            // default search filter names
            category_name: "All",
            product_name: "All",
            // data
            account_type: (localStorage.getItem('account_type') != null ? localStorage.getItem('account_type') : 99),
            account_type_name: (localStorage.getItem('account_type_name') != null ? localStorage.getItem('account_type_name') : "Retail & OP"),
            company: (localStorage.getItem('company') != null ? localStorage.getItem('company') : null),
            companies_name: (localStorage.getItem('company_name') != null ? localStorage.getItem('company_name') : "All"),
            companies: [],
            account_types: {
                0: "Special",
                1: "On-Premise - Cat A",
                9: "On-Premise - Cat B",
                2: "TBS",
                3: "LCBO",
                4: "Individuals",
                5: "Grocery",
                6: "Agency",
                7: "LCBO Warehouse",
                8: "NSLC",
                10: "Convenience",
            },
            categories: {
                account: [{id: "new", name: "New Account"}],
                order: [{id: "new", name: "New Order"}, {id: "delivered", name: "Order Delivered"}, {id: "void", name: "Order Voided"}, {
                    id: "bad_debt",
                    name: "Order Marked Bad Debt"
                }, {id: "promo", name: "Order Marked Promo"}, {id: "bad_debt_reversed", name: "Order Un-Marked Bad Debt"}, {id: "promo_reversed", name: "Order Un-Marked Promo"}],
                payment: [{id: "new", name: "New Payment"}],
                refund: [{id: "new", name: "New Refund / Credit"}, {id: "void", name: "Voided Refund / Credit"}],
                bol: [{id: "new", name: "New BOL"}, {id: "delivered", name: "BOL Delivered"}, {id: "void", name: "BOL Voided"}],
                keg: [{id: "new", name: "New Keg Credit"}],
                product: [{id: "new", name: "New Product Added"}],
            },
            inventory_sources: [],
            products: [],
            staff: [],
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            },
            detail_options: ["Full", "Summary"],
            detail: "Full",
            limit: 500,
            num_rows: 0,
            first_load: true
        };
    }

    componentDidMount() {
        document.title = "Accounting Log | Tobermory Brewing Co";
        var self = this;
        getInventorySources(null, function (inventory_sources) {
            getProducts(function (products) {
                getOwners(function (owners) {
                    self.setState({
                        inventory_sources: inventory_sources,
                        products: products,
                        companies: owners
                    });
                });
            });
        });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate,
                limit: 500
            });
        }
    }

    changeCategory = (category, action) => {
        if (category == null) {
            var category_name = "All"
            var action = null;
        } else {
            var category_name = this.state.categories[category][action].name;
            var action = this.state.categories[category][action].id;
        }
        this.setState({
            category: category,
            action: action,
            category_name: category_name,
            limit: 500
        });
    };
    changeProduct = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name,
            limit: 500
        });
    };
    changeAccountType = (account_type) => {
        if (account_type == null) {
            var account_type_name = "All"
        } else {
            var account_type_name = this.state.account_types[account_type]
        }
        localStorage.setItem('account_type', account_type);
        localStorage.setItem('account_type_name', account_type_name);
        this.setState({
            account_type: account_type,
            account_type_name: account_type_name,
            limit: 500
        });
    }
    changeCompany = (company_id) => {
        if (company_id == null) {
            var companies_name = "All"
        } else {
            var companies_name = this.state.companies[company_id].name
        }
        localStorage.setItem('company', company_id);
        localStorage.setItem('company_name', companies_name);
        this.setState({
            company: company_id,
            companies_name: companies_name,
            limit: 500
        });
    }
    changeDetail = (detail) => {
        this.setState({
            detail: detail,
            limit: 500
        });
    }

    loadMore = event => {
        this.setState({
            limit: this.state.limit + 500,
        }, () => {
            this.getTransactions();
        });
    };

    downloading = event => {
        Swal("Preparing Download", "Please wait, it might take a minute. ", "info");
        const onBlur = () => {
            Swal.close();
        };
        window.addEventListener("blur", onBlur);
    };

    getTransactions() {
        this.setState({
            loading: true,
            first_load: false
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/accounting/log/?category=' + self.state.category + "&action=" + self.state.action + "&product_id=" + self.state.product_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate + "&account_type=" + self.state.account_type + "&company_id=" + self.state.company + '&limit=' + self.state.limit + "&detail=" + self.state.detail)
            .then(function (response) {
                self.setState({
                    transactions: response.data.transactions,
                    num_rows: response.data.num,
                    loading: false

                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        var total_quantity = parseFloat(0);

        return (
            <ContentWrapper>
                <div className="content-heading">
                    Accounting Log
                </div>
                <div className="mb-3 form-inline">
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn mr-1" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                    <InputGroup>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color={(String(this.state.company) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                Companies: <b>{this.state.companies_name}</b>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key="null" onClick={() => this.changeCompany(null)}>All</DropdownItem>
                                <DropdownItem divider/>
                                {Object.keys(this.state.companies).map((key, i) => {
                                        if (localStorage.getItem('companies') == "ALL" || localStorage.getItem('companies').indexOf(',' + key + ',') != -1) {
                                            return (<DropdownItem key={key} onClick={() => this.changeCompany(key)}>{this.state.companies[key].name}</DropdownItem>)
                                        }
                                    }
                                )}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </InputGroup>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color={((String(this.state.category) == "null" && String(this.state.action) == "null") ? "primary" : "warning")}
                                        style={{width: 'auto'}}>
                            Category: <b>{this.state.category_name}</b>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeCategory(null, null)}>All</DropdownItem>
                            {Object.keys(this.state.categories).map((category, key1) =>
                                <div>
                                    <DropdownItem divider/>
                                    {Object.keys(this.state.categories[category]).map((action, key2) =>
                                        <DropdownItem key={key2} onClick={() => this.changeCategory(category, action)}>{this.state.categories[category][action].name}</DropdownItem>
                                    )}
                                </div>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color={(String(this.state.product_id) == "null" ? "primary" : "warning")} style={{width: 'auto'}}>
                            Product: {this.state.product_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.products).map((products_id) =>
                                <DropdownItem key={products_id} onClick={() => this.changeProduct(products_id)}>{this.state.products[products_id].name}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle color={(String(this.state.account_type) == "null" ? "primary" : "warning")} caret style={{fontSize: '13px', width: 'auto'}}>
                            Account Type: <b>{this.state.account_type_name}</b>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeAccountType(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.account_types).map((key, i) =>
                                <DropdownItem key={key} onClick={() => this.changeAccountType(key)}>{this.state.account_types[key]}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret style={{fontSize: '13px', width: 'auto'}}>
                            Report Detail: <b>{this.state.detail}</b>
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.state.detail_options.map((detail, key) =>
                                <DropdownItem key={key} onClick={() => this.changeDetail(detail)}>{detail}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <div className="btn btn-info" onClick={() => this.getTransactions()}>Search</div>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className={(this.state.first_load ? "" : "d-none")}>Select the search criteria and click "Search"</div>
                            <div className={(this.state.first_load ? "d-none" : "card-title")}>
                                {(this.state.num_rows).toLocaleString()} Matching Transactions
                                <a href={API_ROOT + '/accounting/log/?category=' + this.state.category + "&action=" + this.state.action + "&product_id=" + this.state.product_id + "&startDate=" + this.state.startDate + "&endDate=" + this.state.endDate + "&account_type=" + this.state.account_type + "&company_id=" + this.state.company + '&limit=' + this.state.limit + "&detail=" + this.state.detail + "&export=true"}
                                   className="btn btn-secondary float-right" onClick={this.downloading}><i className="fas fa-download mr-1"></i> Export Transactions</a>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.transactions.length > 0 ? "table table-sm table-bordered small" : "d-none")}>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="d-none d-xl-table-cell">ID</th>
                                        <th>Date</th>
                                        <th>Category</th>
                                        <th>Action</th>
                                        <th>Cust. Name</th>
                                        <th>Address</th>
                                        <th>City</th>
                                        <th>Prod. Name</th>
                                        <th>Prod. Category</th>
                                        <th>Factor</th>
                                        <th className="text-right">Qty</th>
                                        <th className="text-right">HL</th>
                                        <th className="text-right">Price</th>
                                        <th className="text-right">Tax</th>
                                        <th className="text-right">Deposit</th>
                                        <th className="text-right">Total</th>
                                        <th className="text-right">Pay Method</th>
                                        <th>Memo</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.transactions.map(function (item, key) {
                                        return (
                                            <tr key={key}>
                                                <td className="d-none d-xl-table-cell">{item.ref_id}</td>
                                                <td>{item.date}</td>
                                                <td>{item.cat}</td>
                                                <td>{item.action}</td>
                                                <td>{item.cust_name}</td>
                                                <td>{item.cust_address}</td>
                                                <td>{item.cust_city}</td>
                                                <td>{item.prod_name}</td>
                                                <td>{item.prod_category}</td>
                                                <td>{item.prod_factor}</td>
                                                <td className="text-right">{item.qty}</td>
                                                <td className="text-right">{(typeof item.hl == "number" ? item.hl.toFixed(3) : "")}</td>
                                                <td className="text-right">{(typeof item.price == "number" ? "$" + item.price.toLocaleString() : "")}</td>
                                                <td className="text-right">{(typeof item.tax == "number" ? "$" + item.tax.toLocaleString() : "")}</td>
                                                <td className="text-right">{(typeof item.deposit == "number" ? "$" + item.deposit.toLocaleString() : "")}</td>
                                                <td className="text-right">{(typeof item.total == "number" ? "$" + item.total.toLocaleString() : "")}</td>
                                                <td className="text-right">{item.pay_method}</td>
                                                <td>{item.memo}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                                <div className={(this.state.num_rows > this.state.transactions.length ? "text-center p-1" : "d-none")} onClick={this.loadMore}><Button
                                    color="primary">
                                    <i className="fas fa-angle-down"></i> Load More</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (AccountingList);
