const Menu = [
    {
        name: 'Dashboard',
        path: 'dashboard',
        icon: 'fa fa-home',
        minRole: 9,
        code: "ALL"
    },
    {
        name: 'Order History',
        path: 'orders',
        icon: 'fas fa-list',
        minRole: 5,
        code: 'OH'
    },
    {
        name: 'Delivery Map',
        path: 'orders/map',
        icon: 'fas fa-map-marker-alt',
        minRole: 5,
        code: 'DM'
    },
    {
        name: 'Accounts',
        icon: 'fas fa-store',
        minRole: 1,
        code: 'AC',
        submenu: [
            {
                name: 'Account List',
                path: 'accounts',
                icon: 'icon-list',
            },
            {
                name: 'Account Map',
                path: 'accounts/map',
                icon: 'fas fa-map-marker-alt',
            }
        ]
    },
    {
        name: 'LCBO Stores',
        path: 'sales/lcbo',
        icon: 'fas fa-store',
        minRole: 2,
        code: 'LC'
    },
   /* {
        name: 'NSLC Stores',
        path: 'sales/nslc',
        icon: 'fas fa-store',
        minRole: 2,
        code: 'NS'
    },*/
    /*{
        name: 'Sales Report',
        icon: 'fas fa-chart-line',
        minRole: 5,
        submenu: [
            {
                name: 'LCBO Stores',
                path: 'sales/lcbo',
                icon: 'fas fa-store',
            },
        ]
    },*/
    {
        name: 'Bill of Ladings',
        path: 'bols/list',
        icon: 'fas fa-dolly',
        minRole: 1,
        code: 'BO'
    }, {
        name: 'Inventory',
        icon: 'far fa-chart-bar',
        minRole: 5,
        code: 'IN',
        submenu: [
            {
                name: 'Overview',
                path: 'inventory',
                icon: 'far fa-chart-bar',
            },
            {
                name: 'Adjust',
                path: 'inventory/adjust',
                icon: 'fas fa-exchange-alt',
            },
            {
                name: 'Transactions',
                path: 'inventory/transactions',
                icon: 'icon-list',
            }
        ]
    }, {
        name: 'Schedule',
        icon: 'far fa-calendar-check',
        minRole: 2,
        code: 'SC',
        submenu: [
            {
                name: 'Call Calendar',
                path: 'schedule/calendar',
                icon: 'far fa-calendar-alt',
            },
            {
                name: 'Map',
                path: 'schedule/map',
                icon: 'fas fa-map-marker-alt',
            }
        ]
    }, {
        name: 'Kegs',
        icon: 'fas fa-coins',
        minRole: 9,
        code: 'KE',
        submenu: [
            {
                name: 'Record Returns',
                path: 'kegs/return',
                icon: 'fas fa-undo',
            },
            {
                name: 'Transactions',
                path: 'kegs/transactions',
                icon: 'icon-list',
            }
        ]
    }, {
        name: 'Products',
        icon: 'fas fa-tags',
        minRole: 1,
        code: 'PO',
        submenu: [
            {
                name: 'View / Edit',
                path: 'products',
                icon: 'fas fa-list-ul',
            },
            {
                name: 'Settings',
                path: 'lists',
                icon: 'fas fa-cog',
            }
        ]
    }, {
        name: 'Users',
        path: 'users',
        icon: 'fas fa-users',
        minRole: 2,
        code: 'US'
    }, {
        name: 'Staff',
        path: 'staff',
        icon: 'far fa-id-badge',
        minRole: 2,
        code: 'ST'
    },/* {
        name: 'Accounting Log',
        path: 'accounting',
        icon: 'fas fa-list-ol',
        minRole: 2,
        code: 'AL'
    }*/
];

export default Menu;
