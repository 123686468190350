import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Card, CardBody, InputGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import 'moment-timezone';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getProducts} from '../Products/ProductFunctions.js';
import {getOwners} from "../Owners/OwnerFunctions";

class InventoryAdjust extends Component {
    constructor(props) {
        super(props);

        this.updateDate = this.updateDate.bind(this);

        this.state = {
            inventory_sources: [],
            product_array: [],
            overview: [],
            effective_date: moment().add(1, 'days'),
            loading: true,
            company: (localStorage.getItem('company') != null ? localStorage.getItem('company') : null),
            companies_name: (localStorage.getItem('company_name') != null ? localStorage.getItem('company_name') : "All"),
            companies: [],
        };
    }

    componentDidMount() {
        document.title = "Inventory Overview | Tobermory Brewing Co";
        this.updateCalc()
    }

    updateCalc = () => {
        var self = this;

        function dynamicSort(property) {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                /* next line works with strings and numbers,
                 * and you may want to customize it to your needs
                 */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        getOwners(function (owners) {
            getInventorySources("can_deliver", function (inventory_sources) {
                getProducts(function (products) {
                    var product_array = [];
                    for (var i in products) {
                        if (products[i].t_inv == 1 && products[i].i_rollup == null) {
                            product_array.push(products[i]);
                        }
                    }
                    self.setState({
                        inventory_sources: inventory_sources,
                        companies: owners,
                        product_array: product_array.sort(dynamicSort("name"))
                    });
                    axios.defaults.withCredentials = true;
                    axios.get(API_ROOT + '/inventory/overview/' + moment.unix(self.state.effective_date / 1000).format("YYYY-MM-DD") + "/" + self.state.company)
                        .then(function (response) {
                            self.setState({
                                overview: response.data,
                                loading: false
                            });
                        })
                        .catch(function (error) {
                            if (error.response.status === 401) {
                                self.props.userSignOut()
                            } else {
                                Swal("Error", error.response.data.Message, "error");
                            }
                        });
                });
            });
        });
    }

    // On update Est Delivery Date
    updateDate(event, picker) {
        event.preventDefault();
        if (event.type == "apply") {
            this.setState({
                effective_date: picker.startDate,
            });
        }
        this.updateCalc()
    }

    changeCompany = (company_id) => {
        if (company_id == null) {
            var companies_name = "All"
        } else {
            var companies_name = this.state.companies[company_id].name
        }
        localStorage.setItem('company', company_id);
        localStorage.setItem('company_name', companies_name);
        this.setState({
            company: company_id,
            companies_name: companies_name
        }, () => {
            this.updateCalc()
        });
    }

    render() {
        var self = this;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Inventory Overview
                </div>
                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>

                        <div className="mb-3 form-inline">
                            <DateRangePicker
                                singleDatePicker={true}
                                date={(this.state.effective_date == null ? moment() : this.state.effective_date)}
                                onEvent={this.updateDate}
                                autoUpdateInput={true}
                            >
                                <div className="btn btn-secondary">
                                    {(this.state.effective_date == null ? 'As of Now' : this.state.effective_date.format('MMM. D, YYYY'))}
                                    <span className="fas fa-caret-down fa-sm"></span>
                                </div>
                            </DateRangePicker>
                            <InputGroup>
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color={(String(this.state.company) == "null" ? "primary" : "warning")} style={{fontSize: '13px', width: 'auto'}}>
                                        Companies: <b>{this.state.companies_name}</b>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem key="null" onClick={() => this.changeCompany(null)}>All</DropdownItem>
                                        <DropdownItem divider/>
                                        {Object.keys(this.state.companies).map((key, i) => {
                                                if (localStorage.getItem('companies') == "ALL" || localStorage.getItem('companies').indexOf(',' + key + ',') != -1) {
                                                    return (<DropdownItem key={key} onClick={() => this.changeCompany(key)}>{this.state.companies[key].name}</DropdownItem>)
                                                }
                                            }
                                        )}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </InputGroup>
                        </div>


                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                <tr>
                                    <th style={{minWidth: "200px"}}>Product</th>
                                    {Object.keys(this.state.inventory_sources).map(function (inventory_source_id, key) {
                                        if (this.state.inventory_sources[inventory_source_id].can_deliver == 1) {
                                            return (
                                                <th key={inventory_source_id}>{this.state.inventory_sources[inventory_source_id].name}</th>
                                            )
                                        }
                                    }, this)}
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.product_array.map(function (key, i) {
                                    var total = 0;
                                    return (<tr key={key} style={{height: "65px"}}>
                                        <td style={{left: 15, position: "absolute", top: "auto", width: "200px", height: "66px", backgroundColor: "#fff"}}
                                            className="font-bold">{self.state.product_array[i].name}</td>
                                        {Object.keys(self.state.inventory_sources).map(function (inventory_source_id, key) {
                                            var product_id = self.state.product_array[i].product_id;
                                            if (self.state.inventory_sources[inventory_source_id].can_deliver == 1) {
                                                var badge = "d-none";
                                                var days = 0;
                                                if (typeof self.state.overview[inventory_source_id] != "undefined" && self.state.overview[inventory_source_id] != null && typeof self.state.overview[inventory_source_id][product_id] != "undefined" && self.state.overview[inventory_source_id][product_id] != null) {
                                                    var inventory = self.state.overview[inventory_source_id][product_id].total;
                                                    if (inventory_source_id == 2 && typeof self.state.overview[inventory_source_id][product_id].recent != "undefined") {
                                                        days = Math.round(self.state.overview[inventory_source_id][product_id].total / (self.state.overview[inventory_source_id][product_id].recent / 24));
                                                        badge = "float-right badge badge-";
                                                        if (days < 30) {
                                                            badge += "danger text-uppercase";
                                                        } else if (days < 60) {
                                                            badge += "warning";
                                                        } else {
                                                            badge += "success";
                                                        }
                                                    }
                                                } else {
                                                    var inventory = "0";
                                                }
                                                total = total + parseFloat(inventory);
                                                return (
                                                    <td key={inventory_source_id}>
                                                        {(Number.isInteger(inventory) || inventory == 0 ? inventory : parseFloat(inventory).toFixed(2))}
                                                        <span className={badge}>{days} days left</span>
                                                    </td>
                                                )
                                            }
                                        }, this)}
                                        <td className="text-bold">{total.toFixed(2)}</td>
                                    </tr>)
                                })}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }
}

export default InventoryAdjust;
