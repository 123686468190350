import React, { Component } from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import { Link } from 'react-router-dom';
import { Card, CardBody,ButtonGroup, Button, Form, FormGroup, Label, Col, Row, Input, InputGroupAddon, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import { API_ROOT } from '../../api-config';
import Swal from 'sweetalert2'
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getProducts} from '../Products/ProductFunctions.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
moment.tz.setDefault("America/Toronto");

class InventoryAdjust extends Component {
    constructor(props){
        super(props);

        this.state = {
            inventory_sources:[],
            dropdown_inventory_source_print1:"(select inventory source)",
            dropdown_inventory_source_print2:"(select inventory destination)",
            products:[],
            product_array:[],
            action_type:null,
            category:null,
            inventory_source_id1:null,
            inventory_source_id2:null,
            notes:undefined,
            selected_products:[{"product_id":null,"quantity":0,"name":"(select product)"}],
            startDate: moment(),
            can_submit:false,
            processing: false
        };
    }
    componentDidMount(){
        document.title = "Adjust Inventory | Tobermory Brewing Co";
        var self=this;
        getInventorySources(null,function(inventory_sources){
            getProducts(function(products){
                var product_array = [];
                for (var i in products) {
                    if (products[i].t_inv==1) {
                        product_array.push(products[i]);
                    }
                }
                self.setState({
                    inventory_sources:inventory_sources,
                    products:products,
                    product_array:product_array
                });
            });
        });
    }

    changeActionType = (action_type) => {
        this.setState({
            action_type: action_type,
            category:null,
            inventory_source_id1:null,
            inventory_source_id2:null,
            dropdown_inventory_source_print1:"(select inventory source)",
            dropdown_inventory_source_print2:"(select inventory destination)",
            selected_products:[{"product_id":null,"quantity":0,"name":"(select product)"}]
        }, () => {
            this.validate();
        });

    };

    changeCategory = (category) => {
        this.setState({
            category: category
        }, () => {
            this.validate();
        });
    };

    changeProduct = (product_num, product_id) => {
        var selected_products =  this.state.selected_products;
        selected_products[product_num].name = this.state.products[product_id].name;
        selected_products[product_num].product_id = product_id;
        this.setState({ selected_products:selected_products}, () => {
            this.validate();
        });
    };

    incrementItem = (product_num, amount) => {
        var selected_products =  this.state.selected_products;
        selected_products[product_num].quantity = (parseInt(selected_products[product_num].quantity)+parseInt(amount));
        this.setState({ selected_products: selected_products }, () => {
            this.validate();
        });
    };

    changeQuantity = (product_num, event) => {
        if(!isNaN(event.target.value)) {
            var selected_products =  this.state.selected_products;
            selected_products[product_num].quantity = event.target.value;
            this.setState({ selected_products: selected_products }, () => {
                this.validate();
            });
        }
    };

    addProduct = () => {
        var selected_products = this.state.selected_products;
        selected_products.push({"product_id":null,"quantity":0,"name":"(select product)"});
        this.setState({ selected_products: JSON.parse(JSON.stringify(selected_products)) });
    };

    validate() {
        var can_submit = false;
        for (var product_num in this.state.selected_products){
            if (this.state.selected_products[product_num].quantity>0 && this.state.selected_products[product_num].product_id>0){
                var can_submit = true;
            }
        }
        this.setState({ can_submit: can_submit});
    }

    changeInventorySource = (inventory_source_id, num) => {
        if (num==1){
            this.setState({
                dropdown_inventory_source_print1:this.state.inventory_sources[inventory_source_id].name,
                inventory_source_id1: inventory_source_id
            });
        } else {
            this.setState({
                dropdown_inventory_source_print2:this.state.inventory_sources[inventory_source_id].name,
                inventory_source_id2: inventory_source_id
            });
        }
    };

    // on date range change
    updateDates(event, picker) {
        if (event.type=="apply") {
            this.setState({
                startDate: picker.startDate
            });
        }
    }
    noteTyping = (event) => {
        this.setState({notes: event.target.value});
    };
    updateNotes = (event) => {
        if (this.state.last_saved_notes!=event.target.value && (this.state.last_saved_notes!=null && event.target.value!="")) { // if it changed, and is not null vs blank
            this.saveOrder({notes:event.target.value});
            this.setState({last_saved_notes: event.target.value});
        }
    };

    handleFocus= function(event) {
        if (event.target.value=="0"){
            event.target.value="";
        }
        event.target.select();
    };

    submitForm = async event => {
        event.preventDefault();
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        var post_data = self.state
        delete post_data.product_array;
        axios.post(API_ROOT + '/inventory/adjustment', post_data)
            .then(function (response) {
                self.changeActionType(null);
                Swal("Done!", "Inventory Change Successfully Processed", "success");
                self.setState({
                    processing: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
            } else {
                Swal("Error", error.response.data.Message, "error");
                self.setState({
                    processing: false
                });
            }
        });
    }

    render() {
        let selected_date = this.state.startDate.format('MMM. D YYYY, h:mm a');
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <Link to="../inventory">Inventory</Link>&nbsp;/&nbsp;Adjust
                </div>
                <div className="text-center mb-3">
                    Use this page to transfer inventory, record new product, product taken for sampling, and reconciliation adjustments
                </div>
                <Card className="card card-default">
                    <CardBody>
                            <FormGroup row>
                                <Label sm={4} lg={3}>Select Adjustment Type:</Label>
                                <ButtonGroup className="pl-3">
                                    <Button color={(this.state.action_type==1?"info":"secondary")} onClick={() => this.changeActionType(1)}>Add</Button>
                                    <Button color={(this.state.action_type==2?"info":"secondary")} onClick={() => this.changeActionType(2)}>Remove</Button>
                                    <Button color={(this.state.action_type==0?"info":"secondary")} onClick={() => this.changeActionType(0)}>Transfer</Button>
                                </ButtonGroup>
                            </FormGroup>
                            <FormGroup row className={(this.state.action_type!=1 && this.state.action_type!=2?"d-none":"")}>
                                <Label sm={4} lg={3}>Category:</Label>
                                <ButtonGroup className="pl-3">
                                    <Button className={(this.state.action_type!=1?"d-none":"")} color={(this.state.category==1?"info":"secondary")} onClick={() => this.changeCategory(1)}>New Product</Button>
                                    <Button className={(this.state.action_type!=2?"d-none":"")} color={(this.state.category==2?"info":"secondary")} onClick={() => this.changeCategory(2)}>Damaged</Button>
                                    <Button className={(this.state.action_type!=2?"d-none":"")} color={(this.state.category==3?"info":"secondary")} onClick={() => this.changeCategory(3)}>Samples</Button>
                                    <Button color={(this.state.category==4?"info":"secondary")} onClick={() => this.changeCategory(4)}>Reconciliation</Button>
                                </ButtonGroup>
                            </FormGroup>
                            <FormGroup row className={(this.state.category==null && this.state.action_type!=0?"d-none":"")}>
                                <Label sm={4} lg={3}>Inventory Source(s):</Label>
                                <Col className="col-sm-8 col-lg-9">
                                    <Form inline>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{width:'210px'}}>
                                                {this.state.dropdown_inventory_source_print1}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.state.inventory_sources).map(function(inventory_source_id, key) {
                                                    return (
                                                        <DropdownItem key={inventory_source_id} onClick={() => this.changeInventorySource(inventory_source_id,1)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                                    )
                                                },this)}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <div className={(this.state.action_type==0?"":"d-none")}>
                                            <i className="fas fa-arrow-right p-1 d-none d-md-inline"></i>
                                            <i className="fas fa-arrow-down pl-5 ml-5 d-none d-xs-block d-sm-block d-md-none"></i>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" caret style={{width:'210px'}}>
                                                    {this.state.dropdown_inventory_source_print2}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.inventory_sources).map(function(inventory_source_id, key) {
                                                        return (
                                                            <DropdownItem key={inventory_source_id} onClick={() => this.changeInventorySource(inventory_source_id,2)}>{this.state.inventory_sources[inventory_source_id].name}</DropdownItem>
                                                        )
                                                    },this)}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Form>
                                </Col>
                            </FormGroup>
                            <FormGroup row className={((this.state.action_type==0 && this.state.inventory_source_id1!=null && this.state.inventory_source_id2!=null) || (this.state.action_type!=0 && this.state.inventory_source_id1!=null)?"":"d-none")}>
                                <Label sm={4} lg={3}>Product(s) & Quantity:</Label>
                                <Col className="pl-3 col-sm-8 col-lg-9">
                                    {Object.keys(this.state.selected_products).map((product_num) =>
                                        <Form inline className="pb-2" key={product_num}>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle caret color="secondary" style={{width:'210px'}}>
                                                    {this.state.selected_products[product_num].name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {this.state.product_array.map((key,i) =>
                                                        <DropdownItem key={key} onClick={() => this.changeProduct(product_num, this.state.product_array[i].product_id)}>{this.state.products[this.state.product_array[i].product_id].name}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <InputGroup className="pl-2">
                                                <InputGroupAddon addonType="prepend" style={{cursor:"pointer"}} onClick={this.incrementItem.bind(this, product_num, -1)}>-</InputGroupAddon>
                                                <Input type="tel" className="form-control text-right" value={this.state.selected_products[product_num].quantity} onFocus={this.handleFocus} onChange={this.changeQuantity.bind(this, product_num)} style={{maxWidth:'60px'}}/>
                                                <InputGroupAddon addonType="append" style={{cursor:"pointer"}} onClick={this.incrementItem.bind(this, product_num, 1)}>+</InputGroupAddon>
                                            </InputGroup>
                                        </Form>
                                    )}
                                    <Button size="sm" color="default" className={(!this.state.can_submit?"d-none":"")} onClick={this.addProduct}>+ Another Product</Button>
                                </Col>
                            </FormGroup>
                            <div className={(!this.state.can_submit?"d-none":"")}>
                                <FormGroup row>
                                    <Label sm={4} lg={3}>Date & Time:</Label>
                                    <Col>
                                        <DateRangePicker
                                            singleDatePicker
                                            startDate={this.state.startDate}
                                            maxDate={moment()}
                                            timePicker={true}
                                            onEvent={this.updateDates.bind(this)}
                                            autoUpdateInput={true}>
                                            <Input value={selected_date} readOnly/>
                                        </DateRangePicker>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4} lg={3}>Notes/Explanation:</Label>
                                    <Col>
                                        <Input type="textarea" style={{height:'150px'}} onChange={this.noteTyping.bind(this)} onBlur={this.updateNotes.bind(this)} value={this.state.notes}></Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm={4} lg={3}></Col>
                                    <Col>
                                        <Button color="primary" onClick={this.submitForm} disabled={this.state.processing}><i className={(this.state.processing?"fa fa-spinner fa-spin mr-2":"d-none")}></i>Submit</Button>
                                    </Col>
                                </FormGroup>
                            </div>
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }

}

export default InventoryAdjust;
